<template>
  <div class="org-users shared-index">
    <h3 class="m-0">Thông tin tổ chức</h3>
    <div class="table">
      <p>
        <span>Tên tổ chức: </span>
        <b class="text-primary">{{ organization.summary }}</b>
      </p>
      <p>
        <span>Mô tả: </span>
        <b class="text-primary">{{ organization.description }}</b>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrgInfomation',
  props: {
    organization: Object
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
</style>
