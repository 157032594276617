<template>
  <el-dialog
    top="80px"
    width="600px"
    class="create-user-popup"
    title="Phân quyền menus cho tổ chức"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Menu mới" prop="menus">
        <el-select
          v-model="form.menus"
          filterable
          multiple
          :disabled="callingAPI"
          placeholder="Chọn menus cho tổ chức"
          class="w-100"
        >
          <el-option
            v-for="(menu, index) in menus.filter((m) => !orgMenus.includes(m.type))"
            :key="index"
            :label="menu.title"
            :value="menu.type"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateOrg">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateOrganization } from '@/services/organizations'

export default {
  name: 'AddUsers',
  props: {
    visible: Boolean,
    organization: Object,
    menus: Array,
    orgMenus: Array,
    actions: Array
  },
  data() {
    return {
      callingAPI: false,
      form: {
        menus: []
      },
      rules: {
        menus: [
          { required: true, message: 'Menu không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    dataInput() {
      return {
        id: this.organization.id,
        menus: [...this.orgMenus, ...this.form.menus].join(',')
      }
    }
  },
  methods: {
    handleUpdateOrg() {
      this.callingAPI = true
      updateOrganization(this.dataInput).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Thêm mới menus thành công'
        })
        this.$refs.form.resetFields()
        this.callingAPI = false
        this.$emit('reload')
        this.$emit('close')
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Không thể thêm menus cho tổ chức'
        })
        this.callingAPI = false
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
