<template>
  <div v-loading="loading" class="org-users shared-index">
    <h3 class="m-0">Phân quyền menus</h3>
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs value="orgUsers" type="card">
          <el-tab-pane name="orgUsers">
            <div slot="label">
              Danh sách Menus
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="visible = true"
        >Thêm menu mới</el-button>
      </div>
    </el-row>
    <div v-if="!loading" class="table">
      <p v-if="!total">Tổ chức chưa có menus nào</p>
      <div v-else>
        <el-tabs
          v-model="activeMenu"
          tab-position="top"
        >
          <el-tab-pane
            v-for="(menu, index) in allOrgMenus"
            :key="index"
            :label="menu.title"
            :name="menu.type"
          >
          </el-tab-pane>
        </el-tabs>
        <permission
          v-if="selectedMenu"
          :key="activeMenu"
          :menu="selectedMenu"
          :actions="actions"
          :org-menus="oldOrgMenus"
          :org-actions="orgActions"
          :organization="organization"
          @reload="() => handleGetOrganizationActions()"
        />
      </div>
    </div>
    <add-menu-popup
      v-if="visible"
      :visible="visible"
      :org-menus="oldOrgMenus"
      :menus="menus"
      :actions="actions"
      :organization="organization"
      @reload="() => $emit('reload')"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getOrganizationActions } from '@/services/organization-action'
import { getActions, getManagedResources } from '@/services/public'
import { getAccountRouters } from '@/utils/filters'
import CONSTANTS from '@/config/constants'

const adminMenus = ['home', 'comment', 'organizations', 'user', 'package', 'staff', 'support']

import Permission from './menus/Permission.vue'
import AddMenuPopup from './popups/AddMenu.vue'

export default {
  name: 'OrgMenus',
  components: {
    Permission,
    AddMenuPopup
  },
  props: {
    organization: Object
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      visible: false,
      actions: [],
      orgActions: [],
      activeMenu: '',
      menus: getAccountRouters().filter((m) => !adminMenus.includes(m.type))
    }
  },
  computed: {
    ...mapGetters(['language']),
    oldOrgMenus() {
      return this.organization && this.organization.menus ? this.organization.menus.split(',') : []
    },
    allOrgMenus() {
      return this.oldOrgMenus.map((m) => this.menus.find((p) => p.type === m))
    },
    total() {
      return this.allOrgMenus.length
    },
    selectedMenu() {
      return this.allOrgMenus.find((m) => m.type === this.activeMenu)
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      await Promise.all([
        this.handleGetActions(),
        this.handleGetManagedResources(),
        this.handleGetOrganizationActions()
      ]).then(() => {
        this.activeMenu = this.allOrgMenus[0] ? this.allOrgMenus[0].type : null
        this.loading = false
      })
    },
    handleGetActions() {
      getActions({}).then((response) => {
        this.actions = CONSTANTS.ACTIONS.map((ac) => {
          const action = response.result.find((a) => a.action_type === ac.key)
          return {
            ...action,
            ...ac
          }
        })
      }).catch(() => {
        this.actions = []
      })
    },
    handleGetManagedResources() {
      getManagedResources({ page: 1, per_page: 200 }).then((response) => {
        this.menus = this.menus.map((me) => {
          const menu = response.result.find((m) => m.resource_type === me.type || (me.apiEndpoint && me.apiEndpoint === m.endpoint))
          return {
            ...menu,
            ...me
          }
        })
      }).catch(() => {
        this.menus = []
      })
    },
    handleGetOrganizationActions() {
      getOrganizationActions({ page: 1, per_page: 300, organization_id: this.organization.id }).then((response) => {
        this.orgActions = response.result
      }).catch(() => {
        this.orgActions = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
