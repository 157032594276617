<template>
  <el-dialog
    top="80px"
    width="900px"
    class="adding-places-popup"
    title="Thêm nội dung cho tổ chức"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" class="p-3">
      <el-form-item label="Loại">
        <el-select v-model="type" disabled class="w-100">
          <el-option
            v-for="t in placeTypes"
            :key="t.value"
            :label="t.name"
            :value="t.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="placeType === 'hierarchy'" label="Phân hệ">
        <el-select
          v-model="form.hierarchies"
          class="w-100"
          multiple
          filterable
          remote
          placeholder="Chọn phân hệ"
          :remote-method="searchObjectByType"
          :loading="loading"
        >
          <el-option v-for="t in hierarchies" :key="t.id" :label="t.name" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-else
        :label="`${currentTab.name} ${['attraction', 'museum'].includes(type) ? 'cha' : ''}`"
      >
        <el-select
          v-model="form.places"
          class="w-100 set-height"
          multiple
          filterable
          remote
          :placeholder="`Chọn ${currentTab.name}`"
          :remote-method="searchObjectByType"
          :loading="loading"
          :disabled="callingAPI"
          @change="changeParentPlaces"
        >
          <el-option
            v-for="p in places"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!['hierarchy'].includes(placeType) && ['attraction', 'museum', 'lookup'].includes(type)"
        :label="`${currentTab.name} con`"
      >
        <el-select
          v-model="form.childrenPlaces"
          class="w-100 set-height"
          multiple
          filterable
          :disabled="callingAPI || !form.places || form.places.length === 0"
          :placeholder="`Chọn ${currentTab.name}`"
          :loading="loading"
        >
          <el-option
            v-for="p in childrenPlaces"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateOrg">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces } from '@/services/place'
import { getHierarchy } from '@/services/hierarchy'

import { getPlaceTypes, orgContentIds } from '@/utils/filters'
import { updateOrganization } from '@/services/organizations'

export default {
  name: 'AddPlaces',
  props: {
    visible: Boolean,
    organization: Object,
    type: String,
    currentTab: Object,
    placeType: String
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      places: [],
      childrenPlaces: [],
      hierarchies: [],
      searchText: '',
      form: {
        type: this.type,
        places: [],
        childrenPlaces: [],
        hierarchies: []
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    oldPlaceIds() {
      return orgContentIds('places', this.organization)
    },
    oldUserIds() {
      return orgContentIds('users', this.organization)
    },
    oldHierarchyIds() {
      return orgContentIds('hierarchies', this.organization)
    },
    dataInput() {
      return {
        id: this.organization.id,
        place_ids: [...new Set([...this.oldPlaceIds, ...this.form.places, ...this.form.childrenPlaces])],
        hierarchy_ids: [...new Set([...this.oldHierarchyIds, ...this.form.hierarchies])],
        user_ids: this.oldUserIds
      }
    },
    placeTypes() {
      return getPlaceTypes(false)
    },
    params() {
      return {
        locale: this.language,
        place_types: this.type,
        per_page: 10,
        search_text: this.searchText,
        only_parent: true
      }
    }
  },
  beforeMount() {
    this.loadData()
    this.getAllChildrenPlaces()
  },
  methods: {
    loadData() {
      this.loading = true
      let request = null
      if (this.placeType === 'hierarchy') {
        request = getHierarchy(this.params)
      } else {
        request = listPlaces(this.params)
      }
      request.then((res) => {
        if (this.placeType === 'hierarchy') {
          this.hierarchies = res.result.filter((p) => !this.oldHierarchyIds.includes(p.id))
        } else {
          this.places = res.result.filter((p) => !this.oldPlaceIds.includes(p.id))
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    getAllChildrenPlaces() {
      if (['attraction', 'museum', 'kiosk', 'lookup'].includes(this.type)) {
        this.loading = true
        listPlaces({
          locale: this.language,
          place_types: this.type,
          per_page: 100000000,
          only_children: true
        }).then((response) => {
          this.childrenPlaces = response.result
          this.loading = false
        }).catch(() => {
          this.childrenPlaces = []
          this.loading = false
        })
      }
    },

    handleUpdateOrg() {
      this.callingAPI = true
      updateOrganization(this.dataInput).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Thêm mới nội dung thành công'
        })
        this.$refs.form.resetFields()
        this.callingAPI = false
        this.$emit('reload')
        this.$emit('close')
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Không thể thêm nội dung cho tổ chức'
        })
        this.callingAPI = false
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },

    changeParentPlaces(parentPlaces) {
      const childrenPlaces = this.childrenPlaces.filter((p) => parentPlaces.includes(p.parent_id))
      this.form.childrenPlaces = childrenPlaces.map((p) => p.id)
    },

    searchObjectByType(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.adding-places-popup {
  .el-dialog__body {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
