<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column width="60">
      <template slot-scope="scope">
        <el-avatar size="large" :src="scope.row.avatar_url"></el-avatar>
      </template>
    </el-table-column>
    <el-table-column label="Tên" prop="name" min-width="200"></el-table-column>
    <el-table-column label="Quyền" prop="role" min-width="200">
      <template slot-scope="scope">
        <el-select :value="scope.row.role" @change="(v) => handleChangeRole(scope.row, v)">
          <el-option label="MEMBER" value="MEMBER" />
          <el-option label="ADMIN" value="ADMIN" />
          <el-option label="OWNER" value="OWNER" />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column label="Địa chỉ email" prop="email" min-width="200"></el-table-column>
    <el-table-column label="Số điện thoại" prop="phone" min-width="200"></el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" width="120"></el-table-column>
    <el-table-column label="Thao tác" width="100" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Xóa user" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="$emit('delete', scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {
  addOwner,
  addMember,
  addAdmin
} from '@/services/organizations'
export default {
  name: 'ListUser',
  props: {
    data: Array,
    owner: Object,
    loading: Boolean
  },
  methods: {
    handleChangeRole(user, role) {
      const payload = {
        organization_id: user.organization_id,
        assigner_id: this.owner.user_id,
        assignee_id: user.user_id
      }
      if (role === 'OWNER') {
        addOwner(payload).then(() => {
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể đổi quyền tài khoản'
          })
        })
      } else if (role === 'ADMIN') {
        addAdmin(payload).then(() => {
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể đổi quyền tài khoản'
          })
        })
      } else {
        addMember(payload).then(() => {
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể đổi quyền tài khoản'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
